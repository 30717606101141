<template>
    <div>
        <p>{{ label }}</p>
        <!-- <p class="text-caption">{{ id }}</p> -->
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        label() {
            return this.attr.label;
        },
    },

};
</script>
