<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-email-audience', params: { accountId: this.$route.params.accountId } }">Email Audiences</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ emailAudience.label }}</h1>
                <!-- <p class="text-caption text-center">Email audience overview</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="emailAudience">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-end mb-0 text-caption grey--text text--darken-1">Audience {{ emailAudience.id }}</p>
                    <v-card>
                        <v-app-bar color="teal darken-2" dark flat dense>
                            <v-app-bar-title>Email audience</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>

                            <p class="text-overline mb-0 mt-2">Label</p>
                            <p class="mb-0 pb-0">
                                {{ emailAudience.label }}
                                <v-btn icon color="teal darken-2" @click="editEmailAudienceLabel">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                            <p class="mb-0 pb-0">
                                {{ emailAudience.comment }}
                                <v-btn icon color="teal darken-2" @click="editEmailAudienceComment">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-8">Email Audience ID</p>
                            <p class="mb-0 pb-0">
                                {{ emailAudience.id }}
                            </p> -->

                            <!-- TODO: how many lists or topics is this audience subscribed to? which ones? -->
                            <!-- <p class="text-overline mb-0 mt-8">Status</p>
                            <p class="mb-0 pb-0">
                                <span v-if="affectedEmailContactList.length > 0">
                                    This email audience is referenced by {{ affectedEmailContactList.length }} templates.
                                </span>
                                <span v-if="affectedEmailContactList.length === 0">
                                This email audience is not currently being used by any email templates.
                                </span>
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-8">Security</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, emailAudienceId: this.$route.params.emailAudienceId } }">Access control</router-link>
                            </p> -->
                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                            <p class="mb-0 pb-0">
                            </p> -->
                            <p class="text-overline mb-0 mt-8">Email Audience Contacts</p>
                            <p class="text-caption mb-4 mt-0">These contacts would receive emails sent to this audience.</p>
                        </v-card-text>
                        <EmailAudienceContactList :emailAudienceId="this.$route.params.emailAudienceId" @selected="onSelectEmailContact"/>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Email Audience Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.emailAudience, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editEmailAudienceLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email audience label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            This is a summary of the match criteria for the audience.
                            You can change it at any time. It is NOT shown to contacts.
                        </p>

                        <!-- <v-row no-gutters align="center"> -->
                            <!-- <v-text-field
                                ref="emailAudienceAliasInput"
                                v-model="editableEmailAudienceComment"
                                label="Alias"
                                :rules="newEmailAudienceCommentRules"
                                validate-on-blur
                                color="teal darken-2"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field> -->
                            <v-text-field
                                ref="emailAudienceLabelInput"
                                v-model="editableEmailAudienceLabel"
                                label="Label"
                                :rules="newEmailAudienceLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                        <!-- </v-row> -->
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailAudienceLabel" :disabled="!isEditEmailAudienceLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailAudienceLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="editEmailAudienceCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the audience alias</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The alias is the way you address the audience in your communications.
                            We call it an alias because it's not necessarily the audience's full name
                            or even their real name. It could be just a first name, or a nickname.
                            For example, "Hello, Sparky!".
                            You can change it at any time.
                        </p>
                        <v-text-field v-model="editableEmailAudienceComment" label="Audience alias"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailAudienceComment" :disabled="!isEditEmailAudienceCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailAudienceCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog> -->
        </v-col>
    </v-row>
</template>

<style>
.row > .v-input:first-of-type {
    margin-right: 4px;
}
.row > .v-input + .v-input {
    margin-left: 4px;
    margin-right: 4px;
}
.row > .v-input:last-of-type {
    margin-left: 4px;
}
</style>

<script>
import { mapState } from 'vuex';
import EmailAudienceContactList from '@/components/account-dashboard/EmailAudienceContactList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        EmailAudienceContactList,
    },
    data: () => ({
        affectedEmailContactList: [],

        error: null,
        account: null,
        emailAudience: null,
        invite: null,
        // edit form title dialog
        editEmailAudienceLabelDialog: false,
        editEmailAudienceCommentDialog: false,
        editableEmailAudienceComment: null,
        editableEmailAudienceLabel: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.emailAudience !== null;
        },
        isEditEmailAudienceLabelComplete() {
            return typeof this.editableEmailAudienceLabel === 'string' && this.editableEmailAudienceLabel.trim().length > 0;
        },
        isEditEmailAudienceCommentComplete() {
            return typeof this.editableEmailAudienceComment === 'string' && this.editableEmailAudienceComment.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadEmailAudience() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailAudience: true });
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.get(this.$route.params.emailAudienceId);
                console.log(`loadEmailAudience: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailAudience = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.alias ?? '';
                    // this.emailAudience.alias ??= '';
                    // this.emailAudience.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email audience');
                }
            } catch (err) {
                console.error('failed to load email audience', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailAudience: false });
            }
        },
        async save(audienceAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditEmailAudience: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.edit({ id: this.$route.params.emailAudienceId }, audienceAttr);
                console.log(`saveEditEmailAudience: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email audience' });
                return false;
            } catch (err) {
                console.error('failed to edit email audience', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email audience' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditEmailAudience: false });
            }
        },
        editEmailAudienceLabel() {
            this.editEmailAudienceLabelDialog = true;
            this.editableEmailAudienceLabel = this.emailAudience.label;
        },
        editEmailAudienceComment() {
            this.editEmailAudienceCommentDialog = true;
            this.editableEmailAudienceComment = this.emailAudience.comment;
        },
        async saveEditEmailAudienceLabel() {
            const isEdited = await this.save({ label: this.editableEmailAudienceLabel });
            this.editEmailAudienceLabelDialog = false;
            if (isEdited) {
                this.$set(this.emailAudience, 'label', this.editableEmailAudienceLabel);
            }
        },
        async saveEditEmailAudienceComment() {
            const isEdited = await this.save({ comment: this.editableEmailAudienceComment });
            this.editEmailAudienceCommentDialog = false;
            if (isEdited) {
                this.$set(this.emailAudience, 'comment', this.editableEmailAudienceComment);
            }
        },
        onSelectEmailContact(item) {
            this.$router.push({ name: 'account-edit-email-contact', params: { accountId: this.$route.params.accountId, emailContactId: item.email_contact_id } });
        },
    },
    mounted() {
        this.loadAccount();
        this.loadEmailAudience();
    },
};
</script>
