<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Add contact to audience</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="onSubmitForm">
                <v-select v-if="emailAudienceId"
                    ref="emailContactIdInput"
                    v-model="newEmailContactId"
                    label="Select email contact"
                    :items="newEmailContactChoices"
                    color="teal darken-2"
                    required
                    outlined
                    >
                </v-select>
                <v-select v-if="emailContactId"
                    ref="emailAudienceIdInput"
                    v-model="newEmailAudienceId"
                    label="Select email audience"
                    :items="newEmailAudienceChoices"
                    color="teal darken-2"
                    required
                    outlined
                    >
                </v-select>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                    <span>Create</span>
                </v-btn>
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    data() {
        return {
            emailAudienceId: null,
            emailContactId: null,

            newEmailAudienceChoices: [],
            newEmailContactChoices: [],

            newEmailAudienceId: null,
            newEmailContactId: null,
        };
    },

    computed: {
        isFormComplete() {
            if (this.emailAudienceId && this.newEmailContactId) {
                return true;
            }
            if (this.emailContactId && this.newEmailAudienceId) {
                return true;
            }
            return false;
        },
    },

    methods: {
        onSubmitForm() {
            if (!this.isFormComplete) {
                return;
            }
            this.createAudienceContact();
        },
        cancel() {
            this.$emit('cancel');
        },
        // TODO: when the add fails, get a reason from the server  -- at least we should be able to tell the user if the contact is already in the audience, or if there's a temporary server issue and to try again later, or if it's something else and they should contact customer support
        async createAudienceContact() {
            try {
                this.error = false;
                this.$store.commit('loading', { createAudienceContact: true });
                console.log('createAudienceContact');
                const request = {};
                if (this.emailAudienceId && this.newEmailContactId) {
                    request.email_audience_id = this.emailAudienceId;
                    request.email_contact_id = this.newEmailContactId;
                }
                if (this.emailContactId && this.newEmailAudienceId) {
                    request.email_audience_id = this.newEmailAudienceId;
                    request.email_contact_id = this.emailContactId;
                }
                const response = await this.$client.account(this.$route.params.accountId).emailAudienceContact.create(request);
                console.log('createAudienceContact response: %o', response);
                const { isCreated, error } = response;
                if (isCreated) {
                    this.$emit('created', request);
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add email contact to audience' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add email contact to audience' });
                    this.error = 'Request failed';
                }
            } catch (err) {
                console.error('Failed to add email contact to audience', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add email contact to audience' });
            } finally {
                this.$store.commit('loading', { createAudienceContact: false });
            }
        },
        async loadEmailAudienceList() {
            try {
                this.$store.commit('loading', { loadEmailAudienceList: true });
                // const match = { email_contact_id: request.emailContactId };
                const match = {}; // TODO: allow user to type in a partial name and search for it
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.search(match);
                if (response?.list) {
                    this.newEmailAudienceChoices = response.list.map((item) => ({ text: item.label, value: item.id }));
                }
            } catch (err) {
                console.error('loadEmailAudienceList failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailAudienceList: false });
            }
        },
        async loadEmailContactList() {
            try {
                this.$store.commit('loading', { loadEmailContactList: true });
                // const match = { email_audience_id: request.emailAudienceId };
                const match = {}; // TODO: allow user to type in a partial name and search for it
                const response = await this.$client.account(this.$route.params.accountId).emailContact.search(match);
                if (response?.list) {
                    this.newEmailContactChoices = response.list.map((item) => ({ text: item.email, value: item.id }));
                }
            } catch (err) {
                console.error('loadEmailContactList failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailContactList: false });
            }
        },
    },
    mounted() {
        if (this.$route.params.emailAudienceId) {
            this.emailAudienceId = this.$route.params.emailAudienceId;
            this.loadEmailContactList();
        }
        if (this.$route.params.emailContactId) {
            this.emailContactId = this.$route.params.emailContactId;
            this.loadEmailAudienceList();
        }
    },
};
</script>
