<template>
    <div>
        <p>{{ alias }} &lt;{{ email }}&gt;</p>
        <p class="text-caption">{{ id }}</p>
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        email() {
            return this.attr.email;
        },
        alias() {
            return this.attr.alias;
        },
    },

};
</script>
