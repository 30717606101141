<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Remove contact from audience</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
                <p>This action is reversible. You can easily add the contact to the audience again later.</p>
            <v-form @submit.prevent="onSubmitForm">
                <p class="text-overline mb-0 mt-8">Email Audience</p>
                <p v-if="emailAudience">{{emailAudience.label}}</p>
                <p v-if="!emailAudience">Loading...</p>
                <p class="text-overline mb-0 mt-8">Email Contact</p>
                <p v-if="emailContact">{{emailContact.email}}</p>
                <p v-if="!emailContact">Loading...</p>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="red white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                    <span>Remove</span>
                </v-btn>
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
        emailAudienceContact: {
            type: Object,
            default: null,
            required: false,
        },
    },

    data() {
        return {
            emailAudienceId: null,
            emailContactId: null,

            emailAudience: null,
            emailContact: null,
        };
    },

    computed: {
        isFormComplete() {
            return this.emailAudienceId && this.emailContactId;
        },
    },

    watch: {
        emailAudienceContact(newValue) {
            this.init(newValue);
        },
    },

    methods: {
        onSubmitForm() {
            if (!this.isFormComplete) {
                return;
            }
            this.deleteAudienceContact();
        },
        cancel() {
            this.emailAudienceId = null;
            this.emailContactId = null;
            this.emailAudience = null;
            this.emailContact = null;
            this.$emit('cancel');
        },
        // TODO: when the remove fails, get a reason from the server
        async deleteAudienceContact() {
            try {
                this.error = false;
                this.$store.commit('loading', { deleteAudienceContact: true });
                console.log('deleteAudienceContact');
                const request = {
                    email_audience_id: this.emailAudienceId,
                    email_contact_id: this.emailContactId,
                };
                const response = await this.$client.account(this.$route.params.accountId).emailAudienceContact.delete(request);
                console.log('deleteAudienceContact response: %o', response);
                const { isDeleted, error } = response;
                if (isDeleted) {
                    this.$emit('deleted', request);
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete email contact to audience' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete email contact to audience' });
                    this.error = 'Request failed';
                }
            } catch (err) {
                console.error('Failed to delete email contact to audience', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete email contact to audience' });
            } finally {
                this.$store.commit('loading', { deleteAudienceContact: false });
            }
        },
        async loadEmailAudience() {
            try {
                this.$store.commit('loading', { loadEmailAudience: true });
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.get(this.emailAudienceId);
                if (response) {
                    this.emailAudience = response;
                }
            } catch (err) {
                console.error('loadEmailAudience failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailAudience: false });
            }
        },
        async loadEmailContact() {
            try {
                this.$store.commit('loading', { loadEmailContact: true });
                const response = await this.$client.account(this.$route.params.accountId).emailContact.get(this.emailContactId);
                if (response) {
                    this.emailContact = response;
                }
            } catch (err) {
                console.error('loadEmailContact failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailContact: false });
            }
        },
        init(emailAudienceContact) {
            console.log(`DeleteAudienceContactDialog: emailAudienceContact: ${JSON.stringify(emailAudienceContact)}`);
            this.emailAudienceId = emailAudienceContact.email_audience_id;
            this.emailContactId = emailAudienceContact.email_contact_id;
            if (this.emailAudienceId) {
                this.loadEmailAudience();
            }
            if (this.emailContactId) {
                this.loadEmailContact();
            }
        },
    },
    mounted() {
        if (this.emailAudienceContact) {
            this.init(this.emailAudienceContact);
        }
    },
};
</script>
